import { Component, OnInit, ViewChild } from '@angular/core';
import { PricebeatApiService } from './service/pricebeat-api.service';
import { BASE_FACT_LIST } from './model/pricebeat.const';
import { GridService } from 'src/app/shared/components/grid/grid-service/grid.service';
import { pageHoverTexts, SELECTOR_TYPES } from 'src/app/models/report-hovertexts.constant';
import { ReportDimension } from 'src/app/models/report-dimension.model';
import { UtilityService } from '../services/utility.service';
import { Subject, forkJoin, map, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { BY_LEVEL_REQUEST_TYPE } from 'src/app/constants/report.constant';
import { GEOGRAPHIC_DEEP_DIVE_DASHBOARD } from 'src/app/constants/dashboard-ui.constant';
import { ProductSearchService } from 'src/app/service/product-search.service';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol.js';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer.js';
import { GisMapComponent } from 'src/app/shared/components/gis-map/gis-map.component';
import { TopbarUtilityService } from 'src/app/core/service/topbar/topbar-utility.service';
import { ToastService } from '@nielseniq/athena-core';
import { TOAST } from 'src/app/constants/toast.constant';

@Component({
	selector: 'crf-ca-walmart-price-beats',
	templateUrl: './walmart-price-beats.component.html',
	styleUrl: './walmart-price-beats.component.scss'
})
export class WalmartPriceBeatsComponent implements OnInit {
	showOverlay = false;
	showDropdown = {
		product: false,
		byLevel: false,
		fact: false,
		period: false
	};
	qid = 7;
	productLevel = BY_LEVEL_REQUEST_TYPE;
	pageHoverTexts = pageHoverTexts;
	levelDisplayName = GEOGRAPHIC_DEEP_DIVE_DASHBOARD.HEADER_NAMES;
	selectedProductToShow: { key: string; level: string; value: string[] } = {
		key: '',
		level: '',
		value: ['']
	};
	beatsCtrl: ReportDimension = {
		dashboardName: 'Walmart Price Beats',
		selectedMarket: [],
		hideText2: true
	};
	selectorTypes = SELECTOR_TYPES;
	periodList: any = [];
	periodData: any = {};
	factList: any = [];
	categoryList: any = [];
	marketList = [];
	defaultMarket = 'Total US';
	currentMarket = 'Total US';
	selectedCMA_Val: any;
	selectedBaseFact: string;
	tableData: any;
	columnSortConfig: { headerName: string; sortOrder: string };
	colDefs: any;
	activeView = 'mapContainer';

	//Table container's
	tspcList = []; //Top list item - map tab table
	bspcList = []; //Bottom list items - map tab table
	wmtsList = []; //Big table - Table tab table
	tspcListLoader: boolean;
	bspcListLoader: boolean;
	isSlicedDetailsTableDataLoading: boolean;
	mapTotalUsData: any = [];
	mapLoader: boolean;

	noDataAvailable: boolean;

	requestPayload: any = {
		department: [],
		categoryGroup: [],
		category: [],
		subCategory: [],
		segment: [],
		byprodlevel: '',
		brandOwner: [],
		brandOwnerLow: [],
		brandHigh: [],
		brandFamily: [],
		brandLow: [],
		totalBusiness: [],
		majorBusiness: [],
		period: '',
		market: ['Total US'],
		cyPeriod: '',
		yaPeriod: ''
	};
	@ViewChild(GisMapComponent) gisMap: GisMapComponent;
	featureLayer: FeatureLayer;

	protected readonly unSubscribeAll: Subject<void> = new Subject<void>();
	pageTitle: any;

	constructor(
		private priceBeatService: PricebeatApiService,
		public gridColumnService: GridService,
		private utilityService: UtilityService,
		private productSearchService: ProductSearchService,
		private topBarUtility: TopbarUtilityService,
		private toastService: ToastService
	) {}

	ngOnInit(): void {
		this.pageTitle = this.topBarUtility.getPageTitleReport();
		this.onInitialLoad();
		this.updateSortAction();
	}
	onInitialLoad() {
		BASE_FACT_LIST.forEach(item => {
			this.factList.push({ key: item.value, value: item.desc });
		});
		this.beatsCtrl.selectedMarket.push(this.defaultMarket);
		const requestArray = [
			this.utilityService.getDefaultSelectors(this.qid),
			this.priceBeatService.getAllBeatsPeriod()
		];
		forkJoin(requestArray)
			.pipe(
				map(([defaultAll, allPeriod]) => {
					return {
						defaultAll: defaultAll,
						allPeriod: allPeriod
					};
				})
			)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe({
				next: response => {
					if (response['allPeriod']) {
						this.periodData.periods = response['allPeriod'];
						Object.keys(this.periodData.periods).forEach(item => {
							if (this.periodData.periods[item].defaultPeriod) {
								this.periodData.default_key = item;
								this.periodData.currentPeriod = this.periodData.periods[item].currentPeriod;
								this.periodData.previousPeriod = this.periodData.periods[item].previousPeriod;
								this.periodData.id = this.periodData.periods[item].id;
							}
							this.periodList.push({ key: this.periodData.periods[item].id, value: item });
						});
					}
					if (response['defaultAll']) {
						this.priceBeatService.defaultSelectors = response['defaultAll'];
						this.onApplyDefault(response['defaultAll'], 7);
					}
					this.getMapData(true);
				},
				error: error => {
					console.log('Error occured', error);
				}
			});
	}

	onApplyDefault(response, selector) {
		if (selector === 3 || selector === 7) {
			let selectedProduct = {
				totalBusiness: response.totalbusiness ? response.totalbusiness.split(';') : [],
				majorBusiness: response.majorbusiness ? response.majorbusiness.split(';') : [],
				department: response?.department ? response.department.split(';') : [],
				categoryGroup: response?.categorygroup ? response.categorygroup.split(';') : [],
				category: response?.category ? response.category.split(';') : [],
				subCategory: response?.subcategory ? response.subcategory.split(';') : [],
				segment: response?.segment ? response.segment.split(';') : [],
				brandOwnerHigh: response?.brandownerhigh ? response.brandownerhigh.split(';') : [],
				brandOwnerLow: response?.brandownerlow ? response.brandownerlow.split(';') : [],
				brandHigh: response?.brandhigh ? response.brandhigh.split(';') : [],
				brandFamily: response?.brandfamily ? response.brandfamily.split(';') : [],
				brandLow: response?.brandlow ? response.brandlow.split(';') : []
			};
			this.updateProductSelection(selectedProduct);
		}

		if (selector === 5 || selector === 7) {
			let data = response.periodIds
				? { key: '', value: response.periodIds }
				: { key: this.periodData.id, value: this.periodData.default_key };
			this.periodSelected(data);
			this.utilityService.preselectDefault(this.periodList, this.beatsCtrl.period);
		}

		if (selector === 3 || selector === 7) {
			let data = response.baseFact
				? this.factList.filter(item => item.key === response.baseFact)[0]
				: this.factList[0];
			this.factSelected(data);
			this.utilityService.preselectDefault(this.factList, this.beatsCtrl.fact);
		}
	}

	setActiveView(tab: string) {
		this.activeView = tab;
		if (tab === 'mapContainer') {
			this.updateMapLayer();
		} else {
			this.colDefs = [
				{
					headerName: 'Product',
					field: 'prodLevel',
					pinned: true,
					width: 250,
					cellClass: 'gridProductName'
				},
				{
					headerName: 'Units Beats',
					field: 'unitsBeats',
					cellFilter: "zip:'roundednumber'",
					cellStyle: params => {
						if (params.value) {
							return this.checkColorByBaseFactValue(params.value, 'Units Beats');
						}
						return null;
					}
				},
				{
					headerName: '% Price Beats',
					field: 'ptBeats',
					cellFilter: "zip:'perc'",
					cellStyle: params => {
						if (params.value) {
							return this.checkColorByBaseFactValue(params.value, '%Beats');
						}
						return null;
					}
				},
				{
					headerName: '% Beats Pt Chg',
					field: 'beatsPtChg',
					cellStyle: params => {
						if (params.value) {
							return this.checkColorByBaseFactValue(params.value, 'Beats%Chg');
						}
						return null;
					}
				},
				{
					headerName: '% Price Gap',
					field: 'priceGap',
					cellFilter: "zip:'perc'",
					cellStyle: params => {
						if (params.value) {
							return this.checkColorByBaseFactValue(params.value, 'Price Gap');
						}
						return null;
					}
				},
				{
					headerName: '% Price Gap Pt Chg',
					field: 'priceGapPtChg',
					cellStyle: params => {
						if (params.value) {
							return this.checkColorByBaseFactValue(params.value, 'Price Gap % Chg');
						}
						return null;
					}
				}
			];
			this.columnSortConfig = {
				headerName: this.beatsCtrl.fact,
				sortOrder: 'desc'
			};
			this.colDefs = this.colDefs.map(ele =>
				ele.headerName === this.beatsCtrl.fact ? { ...ele, sort: 'desc' } : { ...ele, sort: null }
			);
		}
	}

	toggleDropdown(dropdown: string) {
		this.showDropdown[dropdown] = !this.showDropdown[dropdown];
	}

	closeDropdown(dropdown: string) {
		this.showDropdown[dropdown] = false;
	}

	updateProductSelection(data) {
		let lastElement: string;
		this.requestPayload.totalBusiness = data?.totalBusiness;
		this.requestPayload.majorBusiness = data?.majorBusiness;
		this.requestPayload.department = data?.department;
		this.requestPayload.categoryGroup = data?.categoryGroup;
		this.requestPayload.category = data?.category;
		this.requestPayload.subCategory = data?.subCategory;
		this.requestPayload.segment = data?.segment;
		this.requestPayload.brandOwner = data?.brandOwnerHigh;
		this.requestPayload.brandOwnerLow = data?.brandOwnerLow;
		this.requestPayload.brandHigh = data?.brandHigh;
		this.requestPayload.brandLow = data?.brandLow;
		this.requestPayload.brandFamily = data?.brandFamily;

		this.beatsCtrl.selectedProduct = [];
		Object.keys(data).forEach(element => {
			if (!_.isEmpty(data[element])) {
				this.beatsCtrl.selectedProduct.push({
					displayName: this.levelDisplayName[this.productLevel[element]],
					displayValue: data[element]
				});
				lastElement = element;
			}
		});
		if (lastElement && this.beatsCtrl.selectedProduct.length > 0) {
			this.selectedProductToShow = {
				key: lastElement,
				level: this.beatsCtrl.selectedProduct.slice(-1)[0].displayName,
				value: this.beatsCtrl.selectedProduct.slice(-1)[0].displayValue
			};
		}
		this.productSearchService
			.getChildLevels(this.qid, this.productLevel[lastElement])
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(val => {
				this.categoryList = val.result;
				let index = this.categoryList.findIndex(x => x.key === this.productLevel[lastElement]);
				let defaultLevelIndex =
					index === -1
						? 0
						: index === this.categoryList.length - 1
						? this.categoryList.length - 1
						: index + 1;
				this.beatsCtrl.selectedLevelToShow = this.categoryList[defaultLevelIndex]?.value;
				this.utilityService.preselectDefault(this.categoryList, this.beatsCtrl.selectedLevelToShow);
				this.requestPayload.byprodlevel = this.categoryList[defaultLevelIndex]?.key;
				this.getMapData(true);
			});
	}

	getMapData(mapLoad: boolean) {
		if (
			!this.beatsCtrl.selectedLevelToShow ||
			!this.beatsCtrl.selectedMarket ||
			!this.beatsCtrl.fact ||
			!this.beatsCtrl.period
		) {
			return;
		}

		if (mapLoad) {
			this.getMapDataSet();
		}

		this.getTotalMaketData();
		this.getPeriodEndDate();
	}

	getTotalMaketData() {
		//Clearing the previous data
		this.tspcList = [];
		this.bspcList = [];
		this.wmtsList = [];
		this.tspcListLoader = true;
		this.bspcListLoader = true;
		this.isSlicedDetailsTableDataLoading = true;

		this.priceBeatService
			.totalMarketList(this.requestPayload)
			?.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(response => {
				this.tableData = this.priceBeatService.prepareTableData(
					response.metrics,
					this.selectedBaseFact
				);
				this.tspcListLoader = false;
				this.bspcListLoader = false;
				this.fillSelectedCMAVal();
				this.updateTable(this.tableData.top, this.tableData.bottom, this.tableData.main);
			});
	}

	updateTable(topTable, bottomTable, mainTable) {
		this.tspcList = topTable;
		this.bspcList = bottomTable;
		this.wmtsList = mainTable;
		this.isSlicedDetailsTableDataLoading = false;
		if (this.areListsEmpty()) {
			this.showOverlay = true;
			this.noDataAvailable = true;
		} else {
			this.showOverlay = false;
			this.noDataAvailable = false;
		}
	}

	getMapDataSet() {
		this.marketList = [];
		this.mapLoader = true;
		this.priceBeatService
			.getMapData(this.requestPayload)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(response => {
				//Check CMA's Data is avilable data
				if (!_.isNil(response.markets)) {
					this.mapTotalUsData = response;
					this.marketList = response.markets;

					this.updateMapLayer();

					this.fillSelectedCMAVal();
				} else {
					//Show complete data as no data avilable
					if (this.currentMarket !== 'Total US') {
						this.selectedCMA_Val = '';
						this.mapLoader = false;
						this.mapTotalUsData = [];
						this.marketList = [];
						this.showOverlay = true;
						this.noDataAvailable = true;
					} else {
						this.mapTotalUsData = response;
						this.selectedCMA_Val = this.getSelectedCMAVal();
						this.mapLoader = false;
						this.marketList = [];
						if (this.areListsEmpty()) {
							if (!this.tspcListLoader && !this.bspcListLoader) {
								this.showOverlay = true;
								this.noDataAvailable = true;
							}
						} else {
							this.showOverlay = false;
							this.noDataAvailable = false;
						}
					}
				}
			});
	}

	createRender() {
		if (this.marketList && this.marketList.length) {
			let redereValues;

			if (this.selectedBaseFact === 'Units Beats') {
				redereValues = this.getUnitBeatsConfig();
			} else if (this.selectedBaseFact === '%Beats') {
				redereValues = this.getPerBeatsConfig();
			} else {
				redereValues =
					this.selectedBaseFact === 'Price Gap'
						? this.getPriceGapConfig()
						: this.getRenderConfig(this.selectedBaseFact);
			}
			if (redereValues !== 'NA') {
				let rendererf = [];
				redereValues?.forEach(data => {
					rendererf.push({
						minValue: data.minvalue,
						maxValue: data.maxalue,
						symbol: new SimpleFillSymbol({
							type: 'simple-fill',
							color: data.symbol_fill,
							style: 'solid',
							outline: {
								width: 1,
								color: data.symbol_colors
							}
						}),
						label: data.vlabel
					});
				});
				let renderData = new ClassBreaksRenderer({
					field: 'Index',
					classBreakInfos: rendererf
				});
				return renderData;
			} else {
				return this.showDefaultRenderer();
			}
		} else {
			return this.showDefaultRenderer();
		}
	}

	addAttributes() {
		let graphicNamesInRest = _.map(this.marketList, 'market');
		if (this.selectedBaseFact === 'Units Beats') {
			let sortedArray = _.orderBy(
				this.marketList,
				[
					o => {
						let val = o['Units Beats'];
						return val && val.toString().indexOf('.') === -1
							? parseInt(o['Units Beats'])
							: parseFloat(o['Units Beats']);
					}
				],
				['desc']
			);

			this.marketList = sortedArray;
			graphicNamesInRest = _.map(this.marketList, 'market');
		}

		let attributeValues = {};
		let newAttribute;
		this.marketList.forEach(elem => {
			let indexKey = _.indexOf(graphicNamesInRest, elem.market);
			if (indexKey !== -1) {
				if (this.selectedBaseFact === 'Units Beats') {
					newAttribute = {
						[elem.market]: {
							Index: indexKey,
							IndexUB: elem[this.selectedBaseFact]
						}
					};
				} else if (this.selectedBaseFact === '%Beats') {
					newAttribute = {
						[elem.market]: {
							Index: parseFloat(elem[this.selectedBaseFact])
						}
					};
				} else {
					newAttribute = { [elem.market]: { Index: elem[this.selectedBaseFact] } };
				}
			}
			attributeValues = { ...attributeValues, ...newAttribute };
		});
		return attributeValues;
	}

	updateMapLayer() {
		let attributes = this.addAttributes();
		let renderData = this.createRender();
		if (attributes && renderData) {
			this.mapLoader = false;
			setTimeout(() => {
				const title =
					this.beatsCtrl.fact === 'Units Beats' ? 'Units Beat by CMA' : this.beatsCtrl.fact;
				this.gisMap.updateFeatureLayer(title, attributes, renderData);
			}, 1000);
		}
	}

	onMapClick(data) {
		this.currentMarket = data.event.results[0]?.graphic.attributes?.CMA_NAME;
		this.requestPayload = { ...this.requestPayload, market: [this.currentMarket] };
		this.selectedCMA_Val = this.fetchValueBasedonFact(data.event.results[0]?.graphic);
		this.getTotalMaketData();
	}

	onMapHover(data) {
		let fIndex = ['Units Beats', '%Beats', 'Beats%Chg', 'Price Gap', 'Price Gap % Chg'].indexOf(
			this.selectedBaseFact
		);
		let formattingNeeded = fIndex > -1;
		let formattingType = formattingNeeded ? ['', '%', '', '%', ''][fIndex] : '';
		let factValue = this.fetchValueBasedonFact(data.event.results[0]?.graphic);
		data.mapView.openPopup({
			location: data.event.results[0]?.graphic?.geometry.centroid,
			title: data.event.results[0]?.graphic?.attributes?.CMA_NAME,
			content: `<p  style="font-size: 14px"><b> ${this.beatsCtrl.fact} : <span> ${factValue} ${formattingType} </span></b></p>`
		});
	}

	backToTotalUS() {
		this.currentMarket = 'Total US';
		this.requestPayload = { ...this.requestPayload, market: [this.currentMarket] };
		this.gisMap.removeAllSelection();

		let filteredItem = this.getMarketMetricsByCurrentMarket();
		this.selectedCMA_Val = filteredItem ? filteredItem[this.selectedBaseFact] : 'NA';

		this.getMapData(false);
	}

	fetchValueBasedonFact(graphic) {
		let factValue =
			this.selectedBaseFact === 'Units Beats'
				? graphic?.attributes?.IndexUB
				: graphic?.attributes?.Index;
		return factValue;
	}

	getUnitBeatsConfig() {
		let topValueLegend = [];
		let topRanges = {
			one: {
				max: 12,
				min: 0,
				label: 'Most Beats',
				fill_color: [0, 86, 138, 1],
				outline_color: [0, 86, 138, 1]
			},
			two: {
				max: 24,
				min: 13,
				label: ' ',
				fill_color: [0, 123, 196, 1],
				outline_color: [0, 123, 196, 1]
			},
			three: {
				max: 36,
				min: 25,
				label: ' ',
				fill_color: [0, 152, 243, 1],
				outline_color: [0, 152, 243, 1]
			},
			four: {
				max: 49,
				min: 37,
				label: ' ',
				fill_color: [91, 191, 245, 1],
				outline_color: [91, 191, 245, 1]
			},
			five: {
				max: 62,
				min: 50,
				label: 'Fewest Beats',
				fill_color: [180, 224, 252, 1],
				outline_color: [180, 224, 252, 1]
			}
		};

		topValueLegend = [
			this.getTopValueLegendElement(topRanges.five),
			this.getTopValueLegendElement(topRanges.four),
			this.getTopValueLegendElement(topRanges.three),
			this.getTopValueLegendElement(topRanges.two),
			this.getTopValueLegendElement(topRanges.one)
		];

		return topValueLegend;
	}

	getPerBeatsConfig() {
		let topRanges = {
			one: {
				max: 10,
				min: 0,
				label: '0 - 10 %',
				fill_color: [38, 185, 16, 1],
				outline_color: [30, 154, 11, 1]
			},
			two: {
				max: 20,
				min: 10,
				label: '10 - 20 %',
				fill_color: [93, 180, 93, 1],
				outline_color: [75, 154, 75, 1]
			},
			three: {
				max: 30,
				min: 20,
				label: '20 - 30 %',
				fill_color: [151, 219, 151, 1],
				outline_color: [128, 193, 128, 1]
			},
			four: {
				max: 50,
				min: 30,
				label: '30 - 50 %',
				fill_color: [255, 204, 204, 1],
				outline_color: [230, 177, 177, 1]
			},
			five: {
				max: 70,
				min: 50,
				label: '50 - 70 %',
				fill_color: [213, 88, 88, 1],
				outline_color: [162, 77, 77, 1]
			},
			six: {
				max: 1000,
				min: 70,
				label: '70+ %',
				fill_color: [224, 0, 32, 1],
				outline_color: [145, 19, 37, 1]
			}
		};

		return this.getTopValueLegend(topRanges);
	}

	getPriceGapConfig() {
		let topRanges = {
			one: {
				max: 100,
				min: 15,
				label: '15+ %',
				fill_color: [38, 185, 16, 1],
				outline_color: [30, 154, 11, 1]
			},
			two: {
				max: 15,
				min: 10,
				label: '10 - 15 %',
				fill_color: [93, 180, 93, 1],
				outline_color: [75, 154, 75, 1]
			},
			three: {
				max: 10,
				min: 5,
				label: '5 - 10 %',
				fill_color: [151, 219, 151, 1],
				outline_color: [128, 193, 128, 1]
			},
			four: {
				max: 5,
				min: 0,
				label: '0 - 5 %',
				fill_color: [255, 204, 204, 1],
				outline_color: [230, 177, 177, 1]
			},
			five: {
				max: 0,
				min: -5,
				label: '-5 - 0 %',
				fill_color: [213, 88, 88, 1],
				outline_color: [162, 77, 77, 1]
			},
			six: {
				max: -5,
				min: -20,
				label: '> -5 %',
				fill_color: [224, 0, 32, 1],
				outline_color: [145, 19, 37, 1]
			}
		};

		return this.getTopValueLegend(topRanges);
	}

	getRenderConfig(view) {
		function by(o) {
			let val = o[filterByValue];
			return val && val.toString().indexOf('.') === -1
				? parseInt(o[filterByValue])
				: parseFloat(o[filterByValue]);
		}

		let filterByValue = view;
		let topValueLegend = [],
			bottomValueLegend = [],
			cValues = [];
		let topVal, bottomVal, topRagediff, bottomRangediff;

		let maxValue = _.maxBy(this.marketList, function (o) {
			return by(o);
		});

		let minValue = _.minBy(this.marketList, function (o) {
			return by(o);
		});
		//Dividing ranges into 6 sections top 3 and bottom 3

		if (!maxValue && !minValue) {
			return 'NA';
		}

		topVal = maxValue[filterByValue];
		bottomVal = minValue[filterByValue];

		topRagediff = topVal < 3 ? topVal : _.floor(maxValue[filterByValue] / 3, 1);
		bottomRangediff = bottomVal > -3 ? bottomVal : _.floor(minValue[filterByValue] / 3, 1);

		let topRanges = {
			one: {
				max: topRagediff * 1,
				min: 0,
				label:
					'' +
					this.roundOfLegendValues(0, 'No', view) +
					' to ' +
					this.roundOfLegendValues(topRagediff * 1, 'No', view) +
					'',
				fill_color: filterByValue !== 'Beats%Chg' ? [151, 219, 151, 1] : [255, 204, 204, 1],
				outline_color: filterByValue !== 'Beats%Chg' ? [128, 193, 128, 1] : [230, 177, 177, 1]
			},
			two: {
				max: topRagediff * 2,
				min: topRagediff * 1,
				label:
					'' +
					this.roundOfLegendValues(topRagediff * 1, 'No', view) +
					' to ' +
					this.roundOfLegendValues(topRagediff * 2, 'No', view) +
					'',
				fill_color: filterByValue !== 'Beats%Chg' ? [93, 180, 93, 1] : [213, 88, 88, 1],
				outline_color: filterByValue !== 'Beats%Chg' ? [75, 154, 75, 1] : [162, 77, 77, 1]
			},
			three: {
				max: topVal,
				min: topRagediff * 2,
				label:
					'' +
					this.roundOfLegendValues(topRagediff * 2, 'No', view) +
					' to ' +
					this.roundOfLegendValues(topVal, 'No', view) +
					'',
				fill_color: filterByValue !== 'Beats%Chg' ? [38, 185, 16, 1] : [224, 0, 32, 1],
				outline_color: filterByValue !== 'Beats%Chg' ? [30, 154, 11, 1] : [145, 19, 37, 1]
			}
		};

		let botttomRanges = {
			one: {
				max: 0,
				min: bottomRangediff * 1,
				label:
					'' +
					this.roundOfLegendValues(bottomRangediff * 1, 'No', view) +
					' to ' +
					this.roundOfLegendValues(0, 'No', view) +
					'',
				fill_color: filterByValue !== 'Beats%Chg' ? [255, 204, 204, 1] : [151, 219, 151, 1],
				outline_color: filterByValue !== 'Beats%Chg' ? [230, 177, 177, 1] : [128, 193, 128, 1]
			},
			two: {
				max: bottomRangediff * 1,
				min: bottomRangediff * 2,
				label:
					'' +
					this.roundOfLegendValues(bottomRangediff * 2, 'No', view) +
					' to ' +
					this.roundOfLegendValues(bottomRangediff * 1, 'No', view) +
					'',
				fill_color: filterByValue !== 'Beats%Chg' ? [213, 88, 88, 1] : [93, 180, 93, 1],
				outline_color: filterByValue !== 'Beats%Chg' ? [162, 77, 77, 1] : [75, 154, 75, 1]
			},
			three: {
				max: bottomRangediff * 2,
				min: bottomVal,
				label:
					'' +
					this.roundOfLegendValues(bottomVal - 1, 'No', view) +
					' to ' +
					this.roundOfLegendValues(bottomRangediff * 2, 'No', view) +
					'',
				fill_color: filterByValue !== 'Beats%Chg' ? [224, 0, 32, 1] : [38, 185, 16, 1],
				outline_color: filterByValue !== 'Beats%Chg' ? [145, 19, 37, 1] : [30, 154, 11, 1]
			}
		};

		bottomValueLegend = [
			{
				minvalue: botttomRanges.three.min,
				maxalue: botttomRanges.three.max,
				symbol_colors: botttomRanges.three.outline_color,
				symbol_fill: botttomRanges.three.fill_color,
				vlabel: botttomRanges.three.label
			},
			{
				minvalue: botttomRanges.two.min,
				maxalue: botttomRanges.two.max,
				symbol_colors: botttomRanges.two.outline_color,
				symbol_fill: botttomRanges.two.fill_color,
				vlabel: botttomRanges.two.label
			},
			{
				minvalue: botttomRanges.one.min,
				maxalue: botttomRanges.one.max,
				symbol_colors: botttomRanges.one.outline_color,
				symbol_fill: botttomRanges.one.fill_color,
				vlabel: botttomRanges.one.label
			}
		];

		topValueLegend = [
			this.getTopValueLegendElement(topRanges.one),
			this.getTopValueLegendElement(topRanges.two),
			this.getTopValueLegendElement(topRanges.three)
		];

		if (topVal < 3) {
			topValueLegend.splice(1, 2);
		}

		if (bottomVal > -3) {
			bottomValueLegend.splice(0, 2);
		}

		//Render config object
		if (bottomVal < 0 && topVal < 0) {
			cValues = cValues.concat(bottomValueLegend);
		} else if (bottomVal > 0 && topVal > 0) {
			cValues = cValues.concat(topValueLegend);
		} else {
			cValues = cValues.concat(bottomValueLegend);
			cValues = cValues.concat(topValueLegend);
		}
		return cValues;
	}

	roundOfLegendValues(queryToEscape, isDolloar, view) {
		let escapedString: any = 0;
		if (queryToEscape > 0) {
			escapedString = this.escapeQuery(queryToEscape);
			escapedString = isDolloar === 'dollar' ? '$' + escapedString : escapedString;
		} else if (queryToEscape === 0) {
			escapedString = isDolloar === 'dollar' ? '$' + escapedString : escapedString;
		} else {
			if (queryToEscape !== 'NA') {
				escapedString = this.escapeQuery(queryToEscape);
				escapedString = escapedString.replace(/^-/, '');
				escapedString = isDolloar === 'dollar' ? '- $' + escapedString : '-' + escapedString;
			} else {
				escapedString = 'NA';
			}
		}
		escapedString = String(
			isNaN(Number(escapedString)) ? escapedString : Number(escapedString).toFixed(1)
		);
		return view === 'Units Beats' || view === 'Beats%Chg' || view === 'Price Gap % Chg'
			? escapedString
			: escapedString + ' %';
	}

	escapeQuery(queryToEscape) {
		return queryToEscape.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	showDefaultRenderer() {
		let renderer = {
			symbol: new SimpleFillSymbol({
				color: '#97DB97',
				style: 'solid',
				outline: {
					width: 0.2,
					color: '#80C180'
				}
			}),
			label: 'NOT AVAILABLE'
		};
		return renderer;
	}

	getTopValueLegend(topRanges) {
		return [
			{
				minvalue: topRanges.six.min,
				maxalue: topRanges.six.max,
				symbol_colors: topRanges.six.outline_color,
				symbol_fill: topRanges.six.fill_color,
				vlabel: topRanges.six.label
			},
			this.getTopValueLegendElement(topRanges.five),
			this.getTopValueLegendElement(topRanges.four),
			this.getTopValueLegendElement(topRanges.three),
			this.getTopValueLegendElement(topRanges.two),
			this.getTopValueLegendElement(topRanges.one)
		];
	}

	getTopValueLegendElement(topRangesElement) {
		return {
			minvalue: topRangesElement.min,
			maxalue: topRangesElement.max,
			symbol_colors: topRangesElement.outline_color,
			symbol_fill: topRangesElement.fill_color,
			vlabel: topRangesElement.label
		};
	}

	areListsEmpty(): boolean {
		return !this.tspcList.length && !this.bspcList.length && !this.marketList.length;
	}

	fillSelectedCMAVal() {
		if (!_.isNil(this.mapTotalUsData.totalUS) || !_.isNil(this.mapTotalUsData.markets)) {
			let filteredItem: any = this.getMarketMetricsByCurrentMarket();
			if (this.currentMarket !== 'Total US') {
				this.selectedCMA_Val = filteredItem ? filteredItem[this.selectedBaseFact] : ' NA';
			} else {
				this.selectedCMA_Val = this.getSelectedCMAVal();
			}
		} else {
			this.selectedCMA_Val = '';
		}
	}

	getMarketMetricsByCurrentMarket() {
		return this.mapTotalUsData.markets.filter(data => data.market === this.currentMarket)[0];
	}

	getSelectedCMAVal() {
		return this.mapTotalUsData.totalUS && this.mapTotalUsData.totalUS[this.selectedBaseFact]
			? this.mapTotalUsData.totalUS[this.selectedBaseFact]
			: 0;
	}

	getPeriodEndDate() {
		let data = {
			department: this.requestPayload?.department,
			categoryGroup: this.requestPayload?.categoryGroup,
			category: this.requestPayload?.category,
			subCategory: this.requestPayload?.subCategory,
			segment: this.requestPayload?.segment,
			brandOwnerHigh: this.requestPayload?.brandOwner,
			brandOwnerLow: this.requestPayload?.brandOwnerLow,
			brandHigh: this.requestPayload?.brandHigh,
			brandFamily: this.requestPayload?.brandFamily,
			brandLow: this.requestPayload?.brandLow,
			totalBusiness: this.requestPayload?.totalBusiness,
			majorBusiness: this.requestPayload?.majorBusiness
		};
		this.priceBeatService
			.getPeriodEndDate(this.qid, this.periodData.periods[this.beatsCtrl.period].id, data)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => (this.beatsCtrl.periodEndDate = value.periodEndDate));
	}

	levelSelected(data) {
		this.beatsCtrl.selectedLevelToShow = data.value;
		this.requestPayload.byprodlevel = data.key;
		this.getMapData(true);
		this.showDropdown.byLevel = false;
	}

	factSelected(data) {
		this.beatsCtrl.fact = data.value;
		this.selectedBaseFact = data.key;
		this.showDropdown.fact = false;
		this.tableData = this.priceBeatService.prepareTableData(this.wmtsList, this.selectedBaseFact);
		this.fillSelectedCMAVal();
		this.mapLoader = true;
		if (this.marketList.length !== 0) {
			this.updateMapLayer();
			this.updateTable(this.tableData.top, this.tableData.bottom, this.tableData.main);
		}
	}

	periodSelected(data) {
		this.beatsCtrl.period = data.value;
		this.requestPayload.period = data.value;
		this.requestPayload.cyPeriod = this.periodData.periods[data.value].currentPeriod;
		this.requestPayload.yaPeriod = this.periodData.periods[data.value].previousPeriod;
		this.getMapData(true);
		this.showDropdown.period = false;
	}

	onMoreOptionClicked(data) {
		if (data.menuId === 1) {
			let selectorType = this.selectorTypes[data.selectorId].type;
			let selectorValues = this.saveSelectionMetaData();
			let payload = this.getSaveDefaultPayload(data.menuId, data.selectorId, selectorValues);
			let queryParam = this.qid + '?type=' + selectorType;
			if (selectorType !== 'all') {
				this.priceBeatService.defaultSelectors[selectorType] = selectorValues[selectorType];
			} else {
				this.priceBeatService.defaultSelectors = {
					brandownerhigh: this.requestPayload.brandOwnerHigh?.join(';'),
					brandownerlow: this.requestPayload.brandOwnerLow?.join(';'),
					category: this.requestPayload.category?.join(';'),
					categorygroup: this.requestPayload.categoryGroup?.join(';'),
					department: this.requestPayload.department?.join(';'),
					majorbusiness: this.requestPayload.majorBusiness?.join(';'),
					totalbusiness: this.requestPayload.totalBusiness?.join(';'),
					baseFact: selectorValues.baseFact,
					market: null,
					periodIds: selectorValues.periodIds
				};
			}
			this.utilityService
				.saveItemAsDefault(payload, queryParam)
				.pipe(takeUntil(this.unSubscribeAll))
				.subscribe({
					next: res => {
						this.toastService.InjectToast(
							'success',
							'Selections saved successfully',
							'',
							TOAST.TIMEOUT,
							'',
							'Success',
							TOAST.SIZE
						);
					},
					error: err => console.log(err)
				});
		} else if (data.menuId === 2) {
			if (data.selectorId === 7 || data.selectorId === 3) {
				this.requestPayload.brandOwnerHigh = [];
				this.requestPayload.brandOwnerLow = [];
				this.requestPayload.category = [];
				this.requestPayload.categoryGroup = [];
				this.requestPayload.department = [];
				this.requestPayload.majorBusiness = [];
				this.requestPayload.segment = [];
				this.requestPayload.subCategory = [];
				this.requestPayload.totalBusiness = [];
				this.utilityService
					.getDefaultSelectors(this.qid)
					.pipe(takeUntil(this.unSubscribeAll))
					.subscribe(response => {
						this.priceBeatService.defaultSelectors = response;
						this.onApplyDefault(response, 7);
						this.getMapData(true);
					});
			} else {
				this.onApplyDefault(this.priceBeatService.defaultSelectors, data.selectorId);
				this.getMapData(true);
			}
		}
	}

	getSaveDefaultPayload(type, selectorId, params) {
		if (type === 1) {
			let paramsObj = {};
			if (selectorId === 0 || selectorId === 7) {
				paramsObj = {
					brandFamily: '',
					brandHigh: '',
					brandLow: '',
					brandOwnerHigh: this.requestPayload.brandOwnerHigh?.join(';'),
					brandOwnerLow: this.requestPayload.brandOwnerLow?.join(';'),
					category: this.requestPayload.category?.join(';'),
					categoryGroup: this.requestPayload.categoryGroup?.join(';'),
					department: this.requestPayload.department?.join(';'),
					majorBusiness: this.requestPayload.majorBusiness?.join(';'),
					segment: this.requestPayload.segment?.join(';'),
					subCategory: this.requestPayload.subCategory?.join(';'),
					totalBusiness: this.requestPayload.totalBusiness?.join(';')
				};
			}
			for (let i in this.selectorTypes) {
				if ((selectorId == Number(i) || selectorId === 7) && i !== '0') {
					if (params[this.selectorTypes[i].type] !== undefined) {
						paramsObj[this.selectorTypes[i].type] = params[this.selectorTypes[i].type];
					}
				}
			}
			return paramsObj;
		}
	}

	saveSelectionMetaData() {
		return {
			periodIds: this.beatsCtrl.period,
			baseFact: this.selectedBaseFact
		};
	}

	updateSortAction() {
		// Update column value and sort at card level
		this.gridColumnService.sortColumnSubject$
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(res => {
				this.columnSortConfig = res;
			});
	}

	checkColor(value) {
		return this.checkColorByBaseFactValue(value, this.selectedBaseFact);
	}

	checkColorByBaseFactValue(value, baseFactValue) {
		let valCheckColVal = '';
		if (baseFactValue !== '%Beats') {
			if (value < 0) {
				return baseFactValue !== 'Beats%Chg' ? { color: '#DD0014' } : { color: '#26B910' };
			} else if (value > 0) {
				return baseFactValue !== 'Beats%Chg' ? { color: '#26B910' } : { color: '#DD0014' };
			} else {
				return { color: '#000000' };
			}
		} else {
			valCheckColVal =
				value > 70
					? '#e00020'
					: value > 50
					? '#d55858'
					: value > 30
					? '#ffcccc'
					: value > 20
					? '#97db97'
					: value > 10
					? '#5db45d'
					: value > 0
					? '#26b910'
					: '#000000';
			return { color: valCheckColVal };
		}
	}

	reportDownload(type) {
		let exportPayload: any;
		if (type === 'excel') {
			exportPayload = {
				...this.requestPayload,
				applicationType: 'JBP',
				dataModelType: 'GEOBEATS',
				byLevel: this.beatsCtrl.selectedLevelToShow,
				fact: this.selectedBaseFact,
				factType: this.beatsCtrl.fact,
				isTotalMarket: this.currentMarket === 'Total US',
				periodEndDate: this.beatsCtrl.periodEndDate,
				scope: this.selectedProductToShow.key
			};
			this.downloadFile(type, exportPayload);
		} else if (type === 'ppt') {
			let baseFact = this.priceBeatService.getBaseFactByProperty('value', this.selectedBaseFact);
			let tableData = this.buildExportTableData(baseFact);
			exportPayload = {
				applicationType: 'JBP',
				dataModelType: 'PRICEBEATS',
				bylevel: this.beatsCtrl.selectedLevelToShow,
				byprodlevel: this.requestPayload.byprodlevel,
				cyPeriod: this.requestPayload.cyPeriod,
				fact: baseFact.key,
				factType: this.beatsCtrl.fact,
				isTotalMarket: this.currentMarket === 'Total US',
				level: this.beatsCtrl.selectedLevelToShow,
				levelHeaderName: this.beatsCtrl.selectedLevelToShow,
				market: this.requestPayload.market,
				marketValue: this.selectedCMA_Val,
				period: this.beatsCtrl.period,
				periodEndDate: this.beatsCtrl.periodEndDate,
				prodlevel: this.selectedProductToShow.key,
				prodvalue: this.selectedProductToShow.value,
				scope: this.selectedProductToShow.level,
				scopeDisplayValue: this.selectedProductToShow.value[0],
				selectedValue: this.selectedProductToShow.value[0], // Need to check
				yaPeriod: this.requestPayload.yaPeriod,
				tableData: tableData
			};
			this.gisMap.captureScreenshot().then(base64Image => {
				exportPayload.imageBase64 = base64Image;
				this.downloadFile(type, exportPayload);
			});
		}
	}

	downloadFile(type, payload) {
		this.utilityService.exportFile(payload, type);
	}

	buildExportTableData(factObj) {
		let tableData = {
			top: [],
			bottom: []
		};
		tableData.top = this.buildExportProducts(this.tableData?.top, factObj);
		tableData.bottom = this.buildExportProducts(this.tableData?.bottom, factObj);
		return tableData;
	}

	buildExportProducts(products, baseFact) {
		let exportArr = [];
		products.forEach(product => {
			let exportProduct = _.pick(product, 'prodLevel', baseFact.key);
			exportProduct.factColor = this.checkColorByBaseFactValue(
				exportProduct[baseFact.key],
				baseFact.value
			).color;
			exportArr.push(exportProduct);
		});
		return exportArr;
	}

	hideOverlay() {
		this.showOverlay = false;
		this.noDataAvailable = false;
	}
}
