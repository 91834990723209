import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MainPerformanceQuadrantReportComponent } from './main-performance-quadrant-report/main-performance-quadrant-report.component';
import { SELECTORS } from 'src/app/constants/report.constant';
import { ReportFooterContent, ReportFooterItem } from 'src/app/models/report.model';
import { ReplaySubject, takeUntil } from 'rxjs';
import { SelectorConfig } from 'src/app/shared/model/report/report-header.type';
import { selectorConfigurations } from 'src/app/shared/constants/header-configuration/performance-quadrant.constant';
import { FACT_SPECIFICATION } from 'src/app/shared/constants/fact-sepcification/performance-quadrant.constant';
import { HeaderComponent } from 'src/app/shared/components/report/header/header.component';
import { TOAST } from 'src/app/constants/toast.constant';
import { ToastService, ToastState } from '@nielseniq/athena-core';
import { PerformanceQuadrantReportService } from './service/performance-quadrant-report.service';
import { PerformanceQuadrantUtility } from './service/performance-quadrant-utility.service';
import { UtilityService } from 'src/app/reports/services/utility.service';
import { ReportService } from 'src/app/service/report.service';

@Component({
	selector: 'crf-ca-performance-quadrant-report-page',
	templateUrl: './performance-quadrant-report-page.component.html',
	styleUrl: './performance-quadrant-report-page.component.scss'
})
export class PerformanceQuadrantReportPageComponent implements AfterViewInit, OnDestroy {
	@ViewChild(HeaderComponent)
	headerComponent: HeaderComponent;
	@ViewChild(MainPerformanceQuadrantReportComponent)
	mainComponent: MainPerformanceQuadrantReportComponent;

	questionId: number = 5;
	headerComponentSelectorConfiguration: SelectorConfig[] = selectorConfigurations;
	selectorValues: any;
	selectedProducts: any;

	unsubscribeOnDestroy$: ReplaySubject<any> = new ReplaySubject<any>();

	constructor(
		private utility: PerformanceQuadrantUtility,
		private service: PerformanceQuadrantReportService,
		private toastService: ToastService,
		private exportService: UtilityService,
		private reportService: ReportService
	) {}

	ngAfterViewInit() {
		//Load header section
		this.headerComponent
			.getLoadProgress()
			.pipe(takeUntil(this.unsubscribeOnDestroy$))
			.subscribe({
				error: err => {
					this.toast('error', 'Performance Quadrant', 'Ng After View Init: Invalid Response');
				},
				complete: () => {
					console.log('Completed the initialization of the header');
					//Setting column definition
					this.mainComponent.loadColumnDefinition(
						this.utility.getFactColumnDefinition(
							FACT_SPECIFICATION,
							this.headerComponent.getSelectorValues().fact.facts
						)
					);

					//Load main section
					this.loadReport();
				}
			});
	}

	loadReport(): void {
		this.selectorValues = this.headerComponent.getSelectorValues();
		//Getting report payload from header component
		let payload = this.utility.getDataRequestPayload(this.selectorValues);

		//Getting the selected tab from main component
		let selectedTab: string = this.mainComponent.selectedTab;

		//Forming the attributes needed to load the report
		let factType: string = '';
		if (this.selectorValues.fact.factType.length > 0)
			factType = this.selectorValues.fact.factType[0];
		let period: string = '';
		if (this.selectorValues.period.length > 0) period = this.selectorValues.period[0].key;
		let byLevel: string = this.selectorValues.bylevel[0].key;
		byLevel = selectedTab == 'cma-quadrants' ? 'Market' : byLevel;

		//Loading the grid in the main component with the filter parameters
		this.mainComponent.loadReport(byLevel, period, factType, payload);

		//Load fact
		this.mainComponent.loadColumnDefinition(
			this.utility.getFactColumnDefinition(
				FACT_SPECIFICATION,
				this.headerComponent.getSelectorValues().fact.facts
			)
		);

		//Update footer
		this.updateFooter();
	}

	setSelectedProduct(selectedProduct) {
		return this.reportService.setSelectedProduct(selectedProduct, true);
	}

	setMarketArray(selectedMarket) {
		return this.reportService.setMarketArray(selectedMarket);
	}

	updateFooter() {
		let selectorValues = this.headerComponent.getSelectorValues();

		let reportFooterItems: ReportFooterItem[] = this.utility.getFooterItems(selectorValues);

		let updateFooterItems: ReportFooterItem[] = [];

		this.selectedProducts = reportFooterItems.filter(item => item.filter === 'Product')[0].value;

		//Removing value basis if exists
		if (reportFooterItems)
			reportFooterItems.forEach((item, index) => {
				if (
					!(
						(this.mainComponent.selectedTab === 'cma-quadrants' &&
							(item.filter == 'Markets' || item.filter == 'By')) ||
						item.filter == 'Period'
					)
				)
					updateFooterItems.push(item);
			});

		if (this.mainComponent.selectedTab === 'cma-quadrants')
			updateFooterItems.push({ filter: 'Market', value: 'All' });

		updateFooterItems.push({
			filter: 'Period End Date',
			value:
				selectorValues.period[0].key +
				' Ending ' +
				selectorValues.periodenddate.join(';') +
				' vs YA'
		});
	}

	//Main Component
	mainTabChangeHandler(event: any): void {
		//Disable by level and market selector
		if (event == 'cma-quadrants') {
			this.headerComponent.disableFilter(SELECTORS.BY_LEVEL.ID);
			this.headerComponent.disableFilter(SELECTORS.MARKET.ID);
		} else if (event == 'product-quadrants') {
			this.headerComponent.enableFilter(SELECTORS.BY_LEVEL.ID);
			this.headerComponent.enableFilter(SELECTORS.MARKET.ID);
		}

		//Load report data
		this.loadReport();

		//Load footer
		this.updateFooter();
	}

	exportReport(event: any) {
		let payload = this.utility.getPerformanceQuadrantExportPayload(
			this.headerComponent.getSelectorValues(),
			this.mainComponent.selectedTab
		);

		this.exportService.exportFile(payload, 'excel');
	}

	toast(type: ToastState, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}

	ngOnDestroy(): void {
		this.unsubscribeOnDestroy$.next(true);
		this.unsubscribeOnDestroy$.complete();
	}
}
