<div class="container-fluid">
	<div class="row p-3">
		<div class="col-11 d-flex flex-wrap align-items-center">
			<div>
				<div class="mr-4">
					<div class="reportHeader">
						<h4 class="m-0 reportName">{{ pageTitle }}</h4>
					</div>
					<br />
					<div class="mb-0 reportMenu">
						<div>
							<!-- Fact -->
							<span
								class="report-dropdown filter-container mr-5 p-2"
								*ngIf="selectors.fact.config.available"
								promptClickOutside
								(clickOutside)="this.selectors.fact.ui.display = false"
								(click)="$event.stopPropagation()"
								[hidden]="selectors.fact.ui.hidden"
							>
								<a
									class="justify-content-between flex-wrap align-items-center"
									[class.disabled]="selectors.fact.ui.disabled"
									(click)="toggleDropdown(selectors.fact.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltip]="'Choose the Fact you would like to show in this report'"
									niqTooltipPosition="bottom"
									[niqTooltipColorTheme]="'dark'"
									[ngClass]="selectors.fact.ui.display ? 'prompt-expanded' : ''"
								>
									@if (this.selectors.fact.config.multiSelect.facts) {
									<span>{{ selectedFactCount }} Facts</span>
									}@else {
									<span
										>{{
											this.selectors.fact.value.actual.factType[0] === 'DOLLAR'
												? '$ '
												: this.selectors.fact.value.actual.factType[0] === 'UNITS'
												? 'Unit '
												: 'EQ '
										}}
										{{ this.selectors.fact.value.actual.facts[0]?.factGroup }}
									</span>
									}
								</a>
								<crf-ca-fact-selector
									*ngIf="selectors.fact.config.multiSelect.facts && selectors.fact.ui.loaded"
									[questionId]="questionId"
									[hidden]="!selectors.fact.ui.display"
									[factData]="selectors.fact.value.dropdown"
									[isHeaderMultiSelect]="selectors.fact.config.multiSelect.header"
									[isOtherColumnPresent]="selectors.fact.config.column.others"
									[headerValue]="selectors.fact.value.actual.factType[0].toLowerCase()"
									(selectedFacts)="valueChangeHandler($event, selectors.fact.config.id)"
									[isDistributionColumnPresent]="isDistribution"
									[isEQFactColumnPresent]="selectors.fact.config.column.eq"
									[isValueMultiSelect]="selectors.market.value.actual.length > 1"
									[menu]="questionId !== 5"
									(onClose)="closeAllDropdown()"
									(resetFactData)="getSelectedFactCount($event, true)"
									[configId]="questionId"
								>
								</crf-ca-fact-selector>
								<crf-ca-fact-selector-single
									*ngIf="!selectors.fact.config.multiSelect.facts && selectors.fact.ui.loaded"
									[questionId]="questionId"
									[factData]="selectors.fact.value.dropdown"
									[hidden]="!selectors.fact.ui.display"
									(selectedFacts)="valueChangeHandler($event, selectors.fact.config.id)"
									(onClose)="closeAllDropdown()"
								>
								</crf-ca-fact-selector-single>
							</span>

							<span *ngIf="selectors.product.config.available" [hidden]="selectors.fact.ui.hidden"
								>for</span
							>
							<!-- Product -->
							<span
								class="report-dropdown product-filter-container filter-container mr-5 p-2"
								[ngClass]="{ 'error-dropdown': defaultProductError }"
								*ngIf="selectors.product.config.available"
								#product
								promptClickOutside
								(clickOutside)="this.selectors.product.ui.display = false"
							>
								<a
									class="justify-content-between flex-wrap align-items-center"
									[class.disabled]="selectors.product.ui.disabled"
									(click)="toggleDropdown(selectors.product.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltip]="'Choose the Product you would like to show in this report'"
									niqTooltipPosition="bottom"
									[niqTooltipColorTheme]="'dark'"
									[ngClass]="{
										'prompt-expanded': selectors.product.ui.display,
										'prompt-error': defaultProductError && selectors.product.ui.display
									}"
								>
									<span [ngClass]="{ 'error-text': defaultProductError }">
										<span> {{ selectors.product.value.display }} </span>
										<span>
											<niq-icon
												*ngIf="defaultProductError"
												class="error-text"
												size="small"
												iconName="info"
												[niqTooltip]="'Category selection is mandatory'"
												niqTooltipDelay="500"
												niqTooltipPosition="top"
											></niq-icon>
										</span>
									</span>
									<span
										*ngIf="getSelectorCount(selectors.product.config.id) > 1"
										class="badge item-count pl-2"
										>+{{ getSelectorCount(selectors.product.config.id) - 1 }}
									</span>
								</a>
								<crf-ca-multilayer-dropdown
									*ngIf="selectors.product.config.multiSelect"
									[selectorType]="questionId"
									[hidden]="!selectors.product.ui.display"
									(onClose)="selectors.product.ui.display = false"
									(onProductApply)="valueChangeHandler($event, selectors.product.config.id)"
									(click)="onDropdownClick($event)"
								>
								</crf-ca-multilayer-dropdown>
								<crf-ca-product-selector-single
									*ngIf="!selectors.product.config.multiSelect"
									[selectorType]="questionId"
									[hidden]="!selectors.product.ui.display"
									(onClose)="selectors.product.ui.display = false"
									[selectorType]="3"
									[configId]="questionId"
									(updateReport)="valueChangeHandler($event, selectors.product.config.id)"
								></crf-ca-product-selector-single>
							</span>
							<!-- Level -->
							<span *ngIf="selectors.bylevel.config.available">by</span>
							<span
								class="report-dropdown level-filter-container filter-container mr-5 p-2"
								*ngIf="selectors.bylevel.config.available"
								promptClickOutside
								(clickOutside)="this.selectors.bylevel.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									class="flex-wrap justify-content-between align-items-center"
									[class.disabled]="selectors.bylevel.ui.disabled"
									(click)="toggleDropdown(selectors.bylevel.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltip]="
										'Choose the By level breakdown you would like to show in this report'
									"
									niqTooltipPosition="bottom"
									[niqTooltipColorTheme]="'dark'"
									[ngClass]="selectors.bylevel.ui.display ? 'prompt-expanded' : ''"
								>
									<span>{{ selectors.bylevel.value.display }}</span>
								</a>
								<app-dropdown-menu
									*ngIf="!selectors.bylevel.config.multiSelect"
									[hidden]="!selectors.bylevel.ui.display"
									[items]="selectors.bylevel.value.dropdown"
									[showMenuIcon]="false"
									[selectorType]="1"
									(selectedItem)="valueChangeHandler($event, selectors.bylevel.config.id)"
									(cancel)="closeAllDropdown()"
								>
								</app-dropdown-menu>
							</span>
							<!-- Market -->
							<span *ngIf="selectors.market.config.available">in</span>
							<span
								class="report-dropdown filter-container mr-5 p-2"
								*ngIf="selectors.market.config.available"
								promptClickOutside
								(clickOutside)="this.selectors.market.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									class="justify-content-between flex-wrap align-items-center"
									[class.disabled]="selectors.market.ui.disabled"
									(click)="toggleDropdown(selectors.market.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltip]="'Choose the Market you would like to show in this report'"
									niqTooltipPosition="bottom"
									[niqTooltipColorTheme]="'dark'"
									[ngClass]="selectors.market.ui.display ? 'prompt-expanded' : ''"
								>
									<span>{{ selectors.market.value.display }}</span>
									<span
										*ngIf="getSelectorCount(selectors.market.config.id) > 1"
										class="badge item-count pl-2"
										>+{{ getSelectorCount(selectors.market.config.id) - 1 }}
									</span>
								</a>
								<app-multiselect-dropdown
									*ngIf="selectors.market.ui.loaded"
									[placeholder]="'Search markets'"
									[hidden]="!selectors.market.ui.display"
									[selectorType]="2"
									[marketData]="selectors.market.value.dropdown"
									(onApply)="valueChangeHandler($event, selectors.market.config.id)"
									(onCancel)="closeAllDropdown()"
									[questionId]="questionId"
									[menu]="questionId !== 5"
									[multiSelect]="isMarketMultiSelectEnable"
								>
								</app-multiselect-dropdown>
							</span>
							<!-- Period -->
							<span *ngIf="selectors.period.config.available">during</span>
							<span
								class="report-dropdown filter-container mr-5 p-2"
								*ngIf="selectors.period.config.available"
								promptClickOutside
								(clickOutside)="this.selectors.period.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									class="justify-content-between flex-wrap align-items-center"
									[class.disabled]="selectors.period.ui.disabled"
									(click)="toggleDropdown(selectors.period.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltip]="'Choose the Period you would like to show in this report'"
									niqTooltipPosition="bottom"
									[niqTooltipColorTheme]="'dark'"
									[ngClass]="selectors.period.ui.display ? 'prompt-expanded' : ''"
								>
									<span>{{ selectors.period.value.display }}</span>
								</a>
								<app-dropdown-menu
									*ngIf="selectors.period.ui.loaded"
									[hidden]="!selectors.period.ui.display"
									[items]="selectors.period.value.dropdown"
									[selectorType]="5"
									[questionId]="questionId"
									(selectedItem)="valueChangeHandler($event, selectors.period.config.id)"
									(cancel)="closeAllDropdown()"
								>
								</app-dropdown-menu>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<crf-ca-export-report
			[pptIcon]="questionId == 11"
			(onExport)="exportReport($event)"
		></crf-ca-export-report>
		<div class="option-container d-flex align-items-center justify-content-end">
			<!--Save/ Reset default-->
			<crf-ca-save-default
				(toggleOption)="closeAllDropdown()"
				(menuSelector)="saveResetDefault($event, 'ALL')"
				class="save-selection-all"
				[selectorType]="7"
				configId="13"
			>
			</crf-ca-save-default>
		</div>
	</div>
</div>
