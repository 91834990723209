import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastService, ToastState } from '@nielseniq/athena-core';
import * as _ from 'lodash';
import { map, Subject, takeUntil } from 'rxjs';
import { CMA, TOTAL_US } from 'src/app/constants/report.constant';
import { TOAST } from 'src/app/constants/toast.constant';
import { ReportService } from 'src/app/service/report.service';

@Component({
	selector: 'app-multiselect-dropdown',
	templateUrl: './multiselect-dropdown.component.html',
	styleUrl: './multiselect-dropdown.component.scss'
})
export class MultiselectDropdownComponent implements OnInit, OnDestroy {
	selectedMarket: any = [];
	@Input() questionId = -1;
	@Input() selectorType: number;
	@Input() configId: number;
	@Input() marketData: any;
	@Input() disabledDefaultOptions: boolean;
	@Input() menu = true;
	@Input() disableMenuOptions: boolean;
	@Input() multiSelect = true;
	@Input() placeholder: string = '';

	@Output() onCancel = new EventEmitter();
	@Output() onApply = new EventEmitter<any>();
	@Output() onDefaultChange: EventEmitter<any> = new EventEmitter<any>();
	protected readonly unSubscribeAll: Subject<void> = new Subject<void>();
	defaultSelect: any;
	isApplyBtn = true;
	currentSelection: any;
	bannerMarkets: any;
	cmaMarkets: any;
	selectedBannerMarket: string = ''; // Property to track selected banner market
	selectedCMACount = 0;
	accordionExpandedItem = 0;
	marketSections = [{ title: TOTAL_US }, { title: CMA }];
	selectAll: boolean;
	searchKey: any;
	constructor(private service: ReportService, private toastService: ToastService) {}

	ngOnInit() {
		// Store Default
		this.defaultSelect = this.marketData.find(mark => mark.key === TOTAL_US);
		// Store Total US markets
		this.bannerMarkets = this.marketData.filter(
			market =>
				market.value.marketType === 'BANNER' ||
				(market.value.marketType === 'TOTAL' && market.key !== TOTAL_US)
		);
		// Store CMA markets
		this.cmaMarkets = this.marketData.filter(market => market.value.marketType === CMA);
		this.cmaMarkets = [...this.cmaMarkets];
		this.selectedCMACount = this.cmaMarkets.filter(cma => cma.isSelected).length;
		this.bannerMarkets.unshift(this.defaultSelect); // Add TOTAL_US to banner markets as the first element

		this.currentSelection =
			this.marketData?.filter(market => market.isSelected).length === 0
				? [this.defaultSelect]
				: this.marketData?.filter(market => market.isSelected);
		this.selectedMarket =
			this.marketData?.filter(market => market.isSelected).length === 0
				? [this.defaultSelect]
				: this.marketData?.filter(market => market.isSelected);
		this.setButtonState();

		// Banner Code
		if (this.bannerMarkets.some(banner => banner.key === this.selectedMarket[0]?.key)) {
			this.selectedBannerMarket = this.selectedMarket[0].key;
		} else {
			this.selectedBannerMarket = '';
		}
	}

	onBannerSelection(selectedRadio: string) {
		const selectedItem = this.bannerMarkets.find(item => item.key === selectedRadio);
		this.selectedBannerMarket = selectedItem.key;

		// Clear all CMA selections
		this.cmaMarkets.forEach(item => {
			item.isSelected = false;
		});

		// Update Banner selections
		this.bannerMarkets.forEach(item => {
			item.isSelected = item.key === selectedItem.key;
		});

		this.selectedMarket = [selectedItem];
		this.selectedCMACount = this.cmaMarkets.filter(cma => cma.isSelected).length;

		this.setButtonState();
	}

	onSelectionChange() {
		this.bannerMarkets.forEach(item => {
			item.isSelected = false;
		});
		this.selectedBannerMarket = '';
		this.onSelection();
	}

	onSelection() {
		this.selectedMarket = this.marketData.filter(market => market.isSelected);
		this.selectedCMACount = this.cmaMarkets.filter(cma => cma.isSelected).length;

		if (_.isEmpty(this.selectedMarket)) {
			this.onDefaultSelection();
		} else {
			this.setButtonState();
		}
	}

	onSingleSelection(item) {
		this.marketData.map(ele => {
			if (ele.key === item.key) {
				ele.isSelected = true;
			} else {
				ele.isSelected = false;
			}
		});
		this.selectedBannerMarket = '';
		this.onSelection();
	}

	onMarketApply() {
		if (_.isEmpty(this.selectedMarket)) {
			this.currentSelection = [];
			this.onApply.emit([this.defaultSelect]);
		} else {
			this.currentSelection = this.selectedMarket;
			this.onApply.emit(this.selectedMarket);
		}
		this.setButtonState();
	}

	onDefaultSelection() {
		this.bannerMarkets.forEach(item => (item.isSelected = false));
		this.cmaMarkets.forEach(item => (item.isSelected = false));
		this.selectedBannerMarket = TOTAL_US;
		this.selectedMarket = [this.defaultSelect];
		this.setButtonState();
	}

	onSearchChange(query: string) {
		this.searchKey = query;
	}

	getMenuResponse(data) {
		this.onDefaultChange.emit(data);
		if (this.questionId === -1) {
			return;
		}

		if (data.menuId === 1) {
			let market =
				this.selectedMarket.length > 0
					? this.selectedMarket.map(market => market.key).join(';')
					: TOTAL_US;
			let payload = { market: market };
			this.service
				.saveSelectors(this.questionId, 'market', payload)
				.pipe(takeUntil(this.unSubscribeAll))
				.subscribe({
					next: res => {
						this.toast('success', 'Success', 'Selections saved successfully');
					},
					error: err => {
						this.toast('error', 'Failed', 'Invalid Response');
					}
				});
		} else if (data.menuId === 2) {
			this.service
				.getSelectors(this.questionId)
				.pipe(
					map(response => response?.responseData?.market),
					takeUntil(this.unSubscribeAll)
				)
				.subscribe(
					market => {
						if (market) {
							let responseMarkets = market.split(';');
							this.bannerMarkets.forEach(market => {
								market.isSelected = responseMarkets.includes(market.key);
							});
							this.cmaMarkets.forEach(market => {
								market.isSelected = responseMarkets.includes(market.key);
							});
						} else {
							this.bannerMarkets.forEach(item => (item.isSelected = false));
							this.cmaMarkets.forEach(item => (item.isSelected = false));
						}
						this.onSelectionChange();
						this.onMarketApply();
					},
					error => {
						this.toast('error', 'Multiselect Dropdown', 'Get Menu Response: Invalid Response');
					}
				);
		}
	}

	setButtonState() {
		this.isApplyBtn = _.isEqual(this.currentSelection, this.selectedMarket);
	}

	toast(type: ToastState, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}

	sectionToggle(index: number): void {
		if (this.accordionExpandedItem === index) {
			this.accordionExpandedItem = -1;
		} else {
			this.accordionExpandedItem = index;
		}
	}

	/**
	 * On section select and unselect
	 */
	selectAllCMA() {
		this.selectAll = !this.selectAll;
		if (this.selectAll) {
			this.cmaMarkets.forEach(element => {
				element.isSelected = true;
			});
		} else {
			this.cmaMarkets.forEach(element => {
				element.isSelected = false;
			});
		}
		this.onSelection();
	}

	ngOnDestroy(): void {
		this.unSubscribeAll.next();
		this.unSubscribeAll.complete();
	}
}
